import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import { getCourseNavigation } from "../../../../../store/courses"

import Paragraph from "../../../../../components/paragraph"
import Inline from "../../../../../components/inline"
import RotateLetter from "../../../../../components/rotate-letter"
import Text from "../../../../../components/text"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/strophen-und-verse" />
      }
    >
      <Seo title="Mit allen Sinnen lesen" />
      <Stack>
        <Heading as="h2" level={2}>
          Mit allen Sinnen lesen
        </Heading>
        <Stack space={5}>
          <Text sans bold size={[3, 3, 4]}>
            Das kann man in der ersten Strophe sehen, riechen, schmecken und
            fühlen
          </Text>
          <Inline>
            <RotateLetter bg="muted">
              <span role="img" aria-label="gelbe">
                🟡
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Birnen">
                🍐
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="rote Rosen">
                🌹
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Schwäne">
                🦢
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="trunken von Küssen">
                🥰
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="heilignüchtern">
                😇
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Wasser">
                💦
              </span>
            </RotateLetter>
          </Inline>
        </Stack>
        <Stack space={5}>
          <Text sans bold size={[3, 3, 4]}>
            Das kann man in der zweiten Strophe sehen, riechen, schmecken und
            fühlen
          </Text>
          <Inline>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Winter">
                🥶
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Schatten der Erde">
                🌘
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Mauern">
                🧱
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Sprachlos">
                🙊
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="im Winde">
                💨
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="klirren">
                🥂
              </span>
            </RotateLetter>
            <RotateLetter bg="muted">
              <span role="img" aria-label="Fahnen">
                🚩
              </span>
            </RotateLetter>
          </Inline>
        </Stack>
        <Paragraph>
          Zwar werden in beiden Strophen sinnliche Eindrücke geweckt, doch sind
          auch diese sehr unterschiedlicher Natur: Die Landschaftsbeschreibung
          der 1. Strophe ist gefüllt mit Farbe, Sinnlichkeit, Trunkenheit und
          Küssen – eine beinahe schon exzessiv überbordende Szene. Dieser wird
          in der 2. Strophe eine karge und trostlose Winterlandschaft
          gegenübergestellt. Die Blumen, der Sonnenschein und der Schatten sind
          ja gerade nicht da. So ist alles erstarrt und verstummt.
        </Paragraph>
        <Paragraph>
          Getrennt werden die beiden Strophen des Gedichts durch eine Leerzeile,
          die durch die enorme Gegensätzlichkeit der Strophen ein umso größeres
          Gewicht erhält.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
